export const commonMixin = {
  methods: {
    sprintf: function (text, value) {
      if (text.includes("%s")) {
        return text.replace("%s", value)
      }

      return text
    },
    scrollElementInView(elementId, offset, behaviour) {
      const colorList = document.getElementById(elementId);
      if (colorList) {
        const elementPosition = colorList.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: behaviour,
        });
      }
    },
  }
}
