export const numericMixin = {
    methods: {
        parseNumber: function (input) {
            const strInput = String(input);

            if ((strInput.startsWith('0') && strInput.length > 1) ||
                (strInput.startsWith('-0') && strInput.length > 2)) {
                return 0;
            }

            return Number(input);
        }
    }
}