import store from '../store';

export const sampleLimiterMixin = {
    data() {
        return {
            sampleLimit: 3,
            cart: store.state.cart,
        };
    },
    methods: {
        isCartAndSelectedAboveLimit: function () {
            return this.getNumberSamplesCartWithSelected() > this.sampleLimit;
        },
        isSelectedSamplesLimitReached: function () {
            return (this.sampleLimit - Object.values(store.state.selectedSamples).length) === 0;
        },
        isTotalSamplesLimitReached: function () {
            return this.getNumberSamplesCartWithSelected() >= this.sampleLimit;
        },
        getNumberSamplesCart: function () {
            return store.state.samplesInCart;
        },
        incrementSamples: function () {
            store.state.samplesInCart <= this.sampleLimit ? ++store.state.samplesInCart : null;
        },
        decreaseSample: function () {
            store.state.samplesInCart > 0 ? --store.state.samplesInCart : null;
        },
        getNumberSamplesCartWithSelected: function () {
            return this.getNumberSamplesCart() + Object.values(store.state.selectedSamples).length;
        }
    },
    computed: {
        otherProductTypesInCart() {
            const cart = this.state.cart;
            if (!cart || !cart.hasOwnProperty('lineItems')) {
                this.state.samplesInCart = 0;
                this.state.sampleProductsInCart = 0;

                return false;
            }

            let nonSamplesInTheCart = Object.values(cart.lineItems).filter(item => !['colorSamples', 'colorSamplePack'].includes(item.type));
            return nonSamplesInTheCart.length > 0;
        },
    },
};
