import axios from "axios"
import h from "../helpers"
import s from "../store"

const UpdateCheckoutData = (options) => {
  const button = document.querySelector(options.button)
  if (!button) {
    return
  }
  button.addEventListener("click", function () {
    let settings = {
      url: "/",
      data: h.getFormData(button.form, s.state.csrf),
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        "X-Requested-With": "XMLHttpRequest"
      }
    }

    axios(settings).then(response => {
      if (response.data.success) {
        const shippingMethods = response.data.cart.availableShippingMethods
        const methodKeys = Object.keys(shippingMethods)
        if (methodKeys.length === 1) {
          const form = document.querySelector(options.form)
          form.querySelector("input[name=\"shippingMethod\"]").value = shippingMethods[methodKeys[0]].handle
          form.submit()
        }
      }
    })
  })
}

export default UpdateCheckoutData
