import "smoothscroll-polyfill"
import "lazysizes"

import Vue from "vue"
import anime from "animejs"
import axios from "axios"
import Sticky from "sticky-js"
import Vuelidate from 'vuelidate'
import s from "./store"
import h from "./helpers"
import LanguageLink from "./components/LanguageLink"
import CookieBar from "./components/CookieBar"
import {
  handleSubmit,
  initCustomMadeListener,
  initCustomMadeFooter,
  initCustomMadeLeftRightAdditions
} from "./components/CustomMadeForm"
import BackgroundImageFader from "./components/BackgroundImageFader"
import { ScrollLink } from "./components/ScrollLink"
import InfoBar from "./components/InfoBar"
import TextFader from "./components/TextFader"
import Faq from "./components/Faq"
import IntroVideo from "./components/IntroVideo"
import Carousel from "./components/Carousel"
import Modal from "./components/Modal"
import ObjectFit from "./components/ObjectFit"
import Purchaser from "./components/Purchaser"
import ExpandableText from "./components/ExpandableText"
import ProductZoom from "./components/ProductZoom"
import RalColorPalette from "./components/RalColorPalette"
import ViempieField from "./components/ViempieField"
import ViempieSelect from "./components/ViempieSelect"
import SideCart from "./components/SideCart/SideCart"
import HeaderBackground from "./components/HeaderBackground/"
import UpdateCheckoutData from "./components/UpdateCheckoutData"
import "../stylesheets/style.scss"
import en from "vee-validate/dist/locale/en.json"
import fr from "vee-validate/dist/locale/fr.json"
import it from "vee-validate/dist/locale/it.json"
import es from "vee-validate/dist/locale/es.json"
import de from "vee-validate/dist/locale/de.json"
import nl from "vee-validate/dist/locale/nl.json"
import { ValidationProvider, ValidationObserver, localize, extend } from "vee-validate/dist/vee-validate.full"
import productImagesSlider from './components/ProductImagesSlider';
import productZoomMobile from './components/ProductZoomMobile';
import './components'
import autoplayVideo from './components/Video';

import 'swiper/swiper-bundle.css'
import { Swiper, SwiperSlide } from 'swiper-vue2';

Vue.use(Vuelidate)
// Vue.config.devtools = true
// Sentry plugin
import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations"
import FilterComponent from "./components/FilterComponent";
Sentry.init({
  dsn: "https://4f95a66a886e4fb0ba7ec32593440c86@sentry.yournext.agency/50",
  integrations: [new VueIntegration({
    Vue,
    attachProps: true,
    logErrors: true
  })]
})

window.waitForFbqReady = function(sendEvent) {
    let iterations = 0;
    let interval = setInterval(function() {
        iterations++;
        // Wait for 2 minutes
        if (iterations > 120) clearInterval(interval);
        if (typeof window.fbq === 'function') {
            sendEvent();
            clearInterval(interval);
        }
    }, 1000);
}

const locale = document.documentElement.getAttribute("lang")
// add custom validation messages
fr.messages['vat'] = 'Format de numéro de TVA invalide. Exemple : BE0761255511';
it.messages['vat'] = 'Formato della partita IVA non valido. Esempio: BE0761255511';
es.messages['vat'] = 'Formato de número de IVA no válido. Ejemplo: BE0761255511';
de.messages['vat'] = 'Ungültiges UID-Nummernformat. Beispiel: BE0761255511';
nl.messages['vat'] = 'Ongeldig formaat BTW-nummer. Voorbeeld: BE0761255511';
en.messages['vat'] = 'Invalid VAT number format. Example: BE0761255511';

switch (locale) {
    case "fr":
        localize("fr", fr)
        break
    case "it":
        localize("it", it)
        break
    case "es":
        localize("es", es)
        break
    case "de":
        localize("de", de)
        break
    case "nl":
        localize("nl", nl)
        break
    default:
        localize("en", en)
}
Vue.component("ValidationProvider", ValidationProvider)
Vue.component("validation-observer", ValidationObserver)
Vue.component("filter-component", FilterComponent)
Vue.component("swiper", Swiper)
Vue.component("swiper-slide", SwiperSlide)


extend('vat', {
  validate: value => {
      let regexp = /^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$/g;
      if (regexp.test(value)) {
        return true;
      } else {
        false;
      }
  },
  // message: 'tax',
})

// Init plugins
// Vue.use(VeeValidate, {
//   events: "input"
// })

// Declare global filters
Vue.filter("toEur", value => {
  let cleanValue;
  if (typeof value === 'string') {
    cleanValue = value.replace(/[^\d\.]*/g, '');
  } else {
    cleanValue = parseFloat(value);
  }

  const isInt = +cleanValue % 1 === 0;
  const formatted = isInt ? `${Math.abs(+cleanValue)}.-` : Math.abs(+cleanValue).toFixed(2);

  return `€${formatted}`
})

Vue.filter('toInteger', value => {
  return value.substring(0, value.indexOf('.'))
})

if (s.debug) console.log("Global.js file has imported")
/* eslint-disable no-new */
let vm = new Vue({
  el: "#app",
  data: {
    privateState: {},
    sharedState: s.state
  },
  components: {
    "background-image-fader": BackgroundImageFader,
    "text-fader": TextFader,
    "intro-video": IntroVideo,
    "info-bar": InfoBar,
    viempiefield: ViempieField,
    viempieselect: ViempieSelect,
    sidecart: SideCart,
    faq: Faq,
    purchaser: Purchaser,
    "expandable-text": ExpandableText,
  },
  delimiters: ["${", "}"],
  created() {
    // Remove elements created specifically for crawlers
    document.querySelectorAll('[vue-destroy-on-complete]').forEach(el => el.remove())
    s.setCsrf();
  },
  mounted () {
    initCustomMadeListener()
    initCustomMadeFooter()
    initCustomMadeLeftRightAdditions()

    const locale = document.documentElement.getAttribute("lang")
    axios
      .get("/" + locale + "/api/v1/cart.json")
      .then(response => {
        const data = response.data.data[0]
        // Put the whole thang into the state
        s.setCartAction(data)
        s.setPartner(data)
        s.setSamplesInCart()
      })
      .then(() => {
        if (this.sharedState.cart.shippingAddressId) {
          s.setShippingAddressAction(this.sharedState.cart.shippingAddressId)
        } else if (this.sharedState.cart.billingAddressId) {
          s.setBillingAddressAction(this.sharedState.cart.billingAddressId)
        }
      }).catch(function (error) {
        if (s.debug) console.log(error)
      })
  },
  methods: {
    toggleMenuOverlay: () => s.toggleMenuOverlayAction(),
    toggleSideCart: () => s.toggleSideCartAction(),
    toggleIntroVideo: () => s.toggleIntroVideoAction(),
    menuOverlayEnter: (el, done) => handleMenuOverlayEnter(el, done),
    menuOverlayLeave: (el, done) => handleMenuOverlayLeave(el, done),
    validateAndSubmit (event) {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        // Disable button
        const submitBtn = event.target.querySelector("button")
        if (submitBtn) {
          submitBtn.disabled = true
        }
        // Submit the form
        event.target.submit()
      });
    },
    validateAndSubmitWithGTM (event, partner) {
      this.$refs.regForm.validate().then(success => {
        if (!success) {
          return;
        }
        // Disable button
        const submitBtn = event.target.querySelector("button")
        if (submitBtn) {
          submitBtn.disabled = true
        }
        if (typeof dataLayer === "object") {
          dataLayer.push({
            "event": partner ? "partnerRegistered" : "userRegistered",
          })
        }

        // Submit the form
        event.target.submit()
      });
    },
    validateAndLogin(event) {
      // Disable button
      this.$refs.loginForm.validate().then(success => {
        if (!success) {
          return;
        }
        const submitBtn = event.target.querySelector("button")
        if (submitBtn) {
          submitBtn.disabled = true
        }
        // Submit the form
        // event.target.submit()
        const form = event.target
        const redirectField = form.querySelector("input[name='redirect']")
        const submitButton = form.querySelector("button")

        // Post request
        const settings = {
          url: "/",
          data: h.getFormData(form, s.state.csrf),
          method: "POST",
          headers: {
            "Content-type": "multipart/form-data",
            "X-Requested-With": "XMLHttpRequest"
          }
        }

      axios(settings).then(response => {
        if (response.data.errorCode === undefined) {
          window.location = redirectField ? redirectField.value : response.data.returnUrl
        }
        }).catch(function (error) {
          console.log(error)
          const errorMsgSection = document.querySelector("[data-error-message]")
          errorMsgSection.style.display = "block"
          const errorMsgParagraph = errorMsgSection.querySelector("p")
          errorMsgParagraph.textContent = error.response.data.message

          // Enable submit button
          if (submitButton) {
            submitButton.removeAttribute("disabled")
          }
        })
      });
    },
    logout (event) {
      const form = event.target

      // Post request
      const settings = {
        url: "/",
        data: h.getFormData(form, s.state.csrf),
        method: "POST",
        headers: {
          "Content-type": "multipart/form-data",
          "X-Requested-With": "XMLHttpRequest"
        }
      }

      axios(settings).then(response => {
        if (response.data.csrfTokenValue) {
          window.location = ""
        }
      }).catch(function (error) {
        if (s.debug) console.log(error)
      })
    },
    // Being used for the Custom Made Form
    validateAndSave (event) {
      this.$refs.shelfForm.validate().then(success => {
        if (!success) {
          return;
        }
        // Disable button
        const submitBtn = event.target.querySelector("button")
        if (submitBtn) {
          submitBtn.disabled = true
        }
        // init the handleSubmit function from the component CustomMadeForm
        handleSubmit(event)
      });
    }
  }
})

Carousel({
  els: ".js-carousel",
  prev: ".js-prev",
  next: ".js-next",
  slides: ".js-slides"
})

CookieBar({
  el: ".js-cookie-bar",
  toggle: ".js-cookie-toggle"
})

HeaderBackground()

// Init ScrollLinks
ScrollLink("[data-scrollto]")

// Close shit through ESC key
document.addEventListener("keyup", e => {
  if (e.keyCode !== 27) return false
  if (s.state.showMenuOverlay) s.toggleMenuOverlayAction()
  if (s.state.showSideCart) s.toggleSideCartAction()
  if (s.state.showIntroVideo) s.toggleIntroVideoAction()
})

LanguageLink({
  selector: "[data-language-link]"
})

// const observer = lozad("[data-lazyload]", {
//   rootMargin: "10px 0px", // syntax similar to that of CSS Margin
//   threshold: 0.1 // ratio of element convergence
// })
// observer.observe()

window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.loadMode = 1

new Sticky("[data-sticky]")

Modal({
  backdrop: ".js-modal-backdrop",
  dialog: ".js-modal-dialog",
  openers: ".js-modal-open",
  closers: ".js-modal-close"
})

ObjectFit({
  el: ".object-fit"
})

RalColorPalette({
  el: ".js-ral-colors-palette",
  inputField: ".js-ral-colors-palette--input input",
  ralTable: ".js-ral-colors-palette--table",
  vm: vm
})

ProductZoom({
  el: ".js-product-zoom"
})

UpdateCheckoutData({
  button: ".js-checkout-button",
  form: ".js-checkout-form"
})

const handleMenuOverlayEnter = (el, done) => {
  // Animate sidebar
  anime({
    targets: el,
    opacity: [0, 1],
    translateX: [20, 0],
    duration: 400,
    easing: "easeOutCubic",
    complete: done
  })

  // Animate sidebar elements
  const children = el.querySelectorAll("[data-child]")
  anime({
    targets: children,
    opacity: [0, 1],
    translateX: [100, 0],
    duration: 300,
    easing: "easeOutCubic",
    delay: function (el, i, l) {
      return 40 + i * 20
    }
  })
}

const handleMenuOverlayLeave = (el, done) => {
  anime({
    targets: el,
    opacity: 0,
    duration: 300,
    easing: "easeOutCubic",
    complete: done
  })
};

document.addEventListener("click", e => {
    const link = e.target.classList.contains('fbq-link-tracker') ?
        e.target :
        e.target.closest('.fbq-link-tracker');
    if (!link) return;

    const href = link.getAttribute('href');
    const isUrl = !/^(tel|mailto):/.test(href);
    const event = link.getAttribute('data-fbq-event');
    const name = link.getAttribute('data-fbq-name');

    if (['Contact'].indexOf(event) === -1) return;

    if (link.getAttribute('target') === '_blank' || !isUrl) {
        e.preventDefault();

        window.waitForFbqReady(() => {
            if (name) {
                window.fbq('track', event, { content_name: name });
            } else {
                window.fbq('track', event);
            }
        });

        const attrs = { href: href };
        if (isUrl) {
            attrs['rel'] = 'noopener noreferrer';
            attrs['target'] = '_blank';
        }

        // Open the link
        Object.assign(document.createElement('a'), attrs).click();
    } else {
        // TODO: Implement Facebook tracking image loader
    }
});

// Product Images Slider Init
productImagesSlider.init();
productZoomMobile.init();
autoplayVideo();
