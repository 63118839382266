import anime from "animejs"
import Cookies from "js-cookie"
import { initCustomMadeFooter } from "../CustomMadeForm/index"

let el

const handleClick = () => {
  Cookies.set("cookieAccepted", true, { expires: 365 })
  anime({
    targets: el,
    opacity: [1, 0],
    translateY: [0, 32],
    begin: () => {
      el.classList.toggle("dn")
    },
    complete: () => {
      initCustomMadeFooter()
    },
    duration: 350,
    easing: "easeOutCubic"
  })
}

const clickListner = (toggleEl) => {
  anime({
    targets: el,
    opacity: [0, 1],
    translateY: [32, 0],
    begin: () => {
      el.classList.toggle("dn")
    },
    complete: () => {
      initCustomMadeFooter()
    },
    duration: 350,
    easing: "easeOutCubic"
  })

  toggleEl.addEventListener("click", () => handleClick())
}

const cookieBar = (options) => {
  el = document.querySelector(options.el)
  const toggleEl = document.querySelector(options.toggle)

  if (Cookies.get("cookieAccepted")) return

  clickListner(toggleEl)
}

export default cookieBar
