// imports
import Vue from "vue";
import FilterComponent from "./components/FilterComponent";
import ProductList from "./components/ProductList";
import MainProductComponent from "./components/MainProductComponent";
import ColorRadioButton from "./components/ColorRadioButton";
import ProductColor from "./components/ProductColor";
import ProductImage from "./components/ProductImage";
import LoginForm from "./components/LoginForm";
import RegistrationForm from "./components/RegistrationForm";
import AddressesComponent from "./components/AddressesComponent";
import CheckoutCart from "./components/CheckoutCart";
import LinkUpper from "./components/LinkUpper/LinkUpper";
import LangLinkModifier from "./components/LangLinkModifier";
import StateLoader from "./components/StateLoader";
import RadioButton from './components/RadioButton'
import RadioButtonExtended from './components/RadioButtonExtended'
import ExpandableDescription from './components/ExpandableDescription.vue';
import GatewaysSelector from './components/GatewaysSelector.vue';
import CustomerBlock from './components/CustomerBlock.vue';
import ColorSamples from './components/ColorSamples/ColorSamples.vue';
import ColorSamplesSelector from './components/ColorSamples/ColorSamplesSelector.vue';
import ColorSamplesFilters from './components/ColorSamples/ColorSamplesFilters.vue';
import ColorSamplesProducts from './components/ColorSamples/ColorSamplesProducts.vue';
import ColorSamplesVariant from './components/ColorSamples/ColorSamplesVariant.vue';
import PrivateAndBusinessRadioButtons from './components/PrivateAndBusinessRadioButtons.vue';

// component register
Vue.component("private-and-business-buttons", PrivateAndBusinessRadioButtons)
Vue.component("filter-component", FilterComponent)
Vue.component("product-list", ProductList)
Vue.component("main-product-component", MainProductComponent)
Vue.component("color-radio-button", ColorRadioButton)
Vue.component("product-color", ProductColor)
Vue.component("product-image", ProductImage)
Vue.component("login-form", LoginForm)
Vue.component("registration-form", RegistrationForm)
Vue.component("addresses-component", AddressesComponent)
Vue.component("checkout-cart", CheckoutCart)
Vue.component("link-upper", LinkUpper)
Vue.component("lang-link-modifier", LangLinkModifier)
Vue.component("state-loader", StateLoader)
Vue.component("radio-button", RadioButton)
Vue.component("radio-button-extended", RadioButtonExtended)
Vue.component("expandable-description", ExpandableDescription)
Vue.component("gateways-selector", GatewaysSelector)
Vue.component("customer-block", CustomerBlock)
Vue.component("color-samples", ColorSamples)
Vue.component("color-samples-products", ColorSamplesProducts)
Vue.component("color-samples-selector", ColorSamplesSelector)
