import "flickity-imagesloaded"
import Flickity from "flickity"
import "./style.scss"

const Carousel = (options) => {
  const carouselEls = document.querySelectorAll(options.els)
  if (!carouselEls) return

  for (let i = 0; i < carouselEls.length; i++) {
    const carousel = carouselEls[i]
    const prev = carousel.querySelector(options.prev)
    const next = carousel.querySelector(options.next)
    const slides = carousel.querySelector(options.slides)
    initGrid(prev, next, slides)
  }
}

const initGrid = (prevEl, nextEl, slidesEl) => {
  const flkty = new Flickity(slidesEl, {
    imagesLoaded: true,
    wrapAround: true,
    autoPlay: true,
    pauseAutoPlayOnHover: false,
    prevNextButtons: false,
    pageDots: true
  })

  prevEl.addEventListener("click", () => flkty.previous())
  nextEl.addEventListener("click", () => flkty.next())
}

export default Carousel
