import axios from 'axios'
import s from '../../store'
import tinycolor from "tinycolor2"
import './style.scss'
import { updateCustomPrice } from '../CustomMadeForm'

let ralTable = null
let inputField = null
let ralColors = null
let vm = null

// make colours clickable and fill in the ral color code field
function setInputRalColor (event) {
  event.stopPropagation()

  const activeEls = ralTable.querySelectorAll('label.active')
  if (activeEls) {
    for (let i = 0; i < activeEls.length; i++) {
      const element = activeEls[i]
      element.classList.remove('active')
    }
  }

  let target = event.target
  let label
  switch (target.tagName) {
    case 'SPAN':
      label = target.parentElement
      break
    case 'LABEL':
      label = target
      break
    case 'INPUT':
      label = target.parentElement.querySelector('label')
      break
  }

  label.classList.add('active')
  const li = label.parentElement

  //inputField.value = li.querySelector("input").dataset.ralcode
  vm.$refs.paletteColor.currentValue = li.querySelector('input').dataset.ralcode
  vm.$refs.paletteColor.background = li.querySelector('input').dataset.ralhex
  inputField.classList.remove('b--red')
  inputField.classList.add('b--green')
  const errorMessage = inputField.parentNode.parentNode.querySelector('div>span')
  if (errorMessage) {
    errorMessage.remove()
  }
  li.querySelector('input').checked = true
}

const createPallete = () => {
  let pId = document.querySelector('input[id=\'inputPurchasableId\']').value
  let psId = 'purchasables[' + pId + ']'

  for (let i = 0; i < ralColors.length; i++) {
    const ralColor = ralColors[i]
    let ralName = ralColor.RAL.trim();
    let isRalColor =  ralColor.RAL.startsWith('RAL');
    if (isRalColor) {
        ralName = ralColor.RAL.replace(/\s/g, '')
    }

    const ralCodeInput = document.createElement('input')
    ralCodeInput.type = 'radio'
    ralCodeInput.name = psId + '[options][ralColorCode]'
    ralCodeInput.id = 'ralColorCode-' + i
    ralCodeInput.value = ralColor.RAL.replace(/\s/g, '')
    ralCodeInput.classList.add('hidden')
    ralCodeInput.dataset.ralcode = ralName
    ralCodeInput.dataset.ralhex = ralColor.HEX

    const ralCodeSpan = document.createElement('span')
    ralCodeSpan.textContent = ralColor.RAL
    if (ralColor.HEX) {
      const isLight = tinycolor(ralColor.HEX);
      ralCodeSpan.classList.add(isLight.isLight() ? 'dark' : 'light');
    }

    const ralCodeWrap = document.createElement('label')
    ralCodeWrap.htmlFor = 'ralColorCode-' + i
    ralCodeWrap.appendChild(ralCodeSpan)

    const ralCodeLi = document.createElement('li')
    ralCodeLi.style.backgroundColor = ralColor.HEX
    ralCodeLi.appendChild(ralCodeInput)
    ralCodeLi.appendChild(ralCodeWrap)

    // set eventlistener
    ralCodeInput.addEventListener('click', setInputRalColor)
    ralCodeInput.addEventListener('click', updateCustomPrice)
    ralTable.appendChild(ralCodeLi)
  }
}

const getPalleteColors = (initValue = false) => {
  const locale = document.documentElement.getAttribute('lang')
  axios.get('/' + locale + '/ralcolors.json')
    .then(function (response) {
      ralColors = response.data
      createPallete()
      if (initValue) {
        let initInput = document.querySelector('input[value="' + initValue + '"]')
        if (initInput) initInput.click()
      }
    })
    .catch(function (error) {
      if (s.debug) console.log(error)
    })
}

// init ralColors listener
const RalColorPallete = (options) => {
  const el = document.querySelector(options.el)
  if (!el) return
  ralTable = document.querySelector(options.ralTable)
  inputField = document.querySelector(options.inputField)
  vm = options.vm
  getPalleteColors(options.initValue)
}

export default RalColorPallete
