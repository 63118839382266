import anime from "animejs"

let backdrop = null
let dialogs = null
let activeDialog = null
let openers = null
let closers = null
const activeClass = "active"

const Modal = options => {
  backdrop = document.querySelector(options.backdrop)
  dialogs = document.querySelectorAll(options.dialog)
  openers = document.querySelectorAll(options.openers)
  closers = document.querySelectorAll(options.closers)
  if (!dialogs) return false
  // Open shit
  for (let i = 0; i < openers.length; i++) {
    const el = openers[i]
    const id = el.getAttribute("data-modal-id")
    el.addEventListener("click", () => showModal(id))
  }
  // Close shit
  for (let i = 0; i < closers.length; i++) {
    const el = closers[i]
    el.addEventListener("click", hideModal)
  }
  // Close shit through ESC key
  // document.addEventListener("keyup", e => {
  //   e.keyCode === 27 ? hideModal() : null
  // })
  // Hide modal via click on backdrop
  backdrop.addEventListener("click", hideModal)
  // Prevent closing via click on dialogs
  for (let i = 0; i < dialogs.length; i++) {
    const el = dialogs[i]
    el.addEventListener("click", e => e.stopPropagation())
  }
}

const showBackdrop = () => {
  document.body.style.overflow = "hidden"
  backdrop.style.display = "block"
  // Animate in backdrop
  anime({
    targets: backdrop,
    opacity: [0, 1],
    duration: 400,
    easing: "easeOutCubic",
    elasticity: 1500
  })
}

const hideBackdrop = () => {
  document.body.style.overflow = "auto"
  // Animate out backdrop
  anime({
    targets: backdrop,
    opacity: 0,
    duration: 400,
    easing: "easeOutCubic",
    elasticity: 150,
    complete: () => {
      backdrop.style.display = "none"
    }
  })
}

const showModal = id => {
  showBackdrop()
  const dialog = document.getElementById(id)
  dialog.classList.add(activeClass)
  dialog.style.display = "block"
  // Animate in dialog
  anime({
    targets: dialog,
    opacity: [0, 1],
    scale: [1.1, 1],
    duration: 200,
    easing: "easeOutCubic",
    elasticity: 150,
    delay: 50
  })
  // Create event that Isotope can listen to
  const event = new Event("modalopened")
  document.dispatchEvent(event)
}

const hideModal = () => {
  hideBackdrop()
  activeDialog = document.querySelector(".js-modal-dialog.active")
  // Animate out modal
  anime({
    targets: activeDialog,
    opacity: 0,
    scale: 0.9,
    duration: 250,
    easing: "easeOutCubic",
    elasticity: 150,
    complete: () => {
      activeDialog.classList.remove(activeClass)
      activeDialog.style.display = "none"
    }
  })
}

export { hideModal }
export default Modal
